import { Controller } from "@hotwired/stimulus";
import { sendTurboStreamRequest } from "../helpers/turbo_stream_helper"
import { buildHeaders, buildRequest, buildUrl } from "../helpers/build_request"

// Connects to data-controller="shipment"
export default class extends Controller {

  sendTurboRequest(event) {
    const selectBox = event.target;
    const turboParams = JSON.parse(selectBox.dataset.turboParams);

    const params = {stock_card_id: selectBox.value, ...turboParams};
    delete params.url;
    if (params.stock_card_id !== '') {
      const url = buildUrl(turboParams.url, params);
      const request = buildRequest(url, buildHeaders());
      sendTurboStreamRequest(request);
    }
  }
}
