import { Controller } from "@hotwired/stimulus"
import Tabulator from "../../extensions/TabulatorExtended";

// Connects to data-controller="tabulator--consumption-trackings"
export default class extends Controller {
  static values = {
    url: String,
    columns: Array,
    locale: String
  }

  connect() {
    this.tabulator = new Tabulator(this.element, {
      ajaxURL: this.urlValue,
      ajaxConfig: "GET",
      layout: "fitColumns",
      pagination: true,
      paginationMode: 'remote',
      paginationSize: 15,
      paginationSizeSelector: [15, 25, 50, 100],
      sortMode: 'remote',
      columns: this.processedColumns(this.columnsValue),
      locale: this.localeValue,
      dataLoaderLoading: "<div class='tabulator-spinner'></div>",
      paginationCounter: "pageCounter",
      ajaxRequesting: (url, params) => {
        this.processedSorters(params);
        return true;
      },
    });
  }

  processedColumns(columns) {
    const formatterMap = {
      total_quantity: this.totalQuantityFormatter.bind(this),
    };

    return columns.map((column) => {
      if (formatterMap[column.field]) {
        return {
          ...column,
          formatter: formatterMap[column.field],
        };
      }
      return column;
    });
  }

  totalQuantityFormatter(cell) {
    const cellValue = cell.getValue();
    const div = document.createElement('div');
    div.classList.add("flex", "items-center", "justify-end");
    div.innerHTML = cellValue;

    return div;
  }

  processedSorters(params) {
    if (params.sort.length > 0) {
      const fieldMapping = {
        company: "companies.name",
        request_no: "warehouse_stocks.request_no",
        created_by: "users.name",
        movement_date: "warehouse_stocks.movement_date",
        consumption_description: "warehouse_stocks.movement_type",
        associated_repos: "warehouse_stocks.warehouse_id", 
        consumption_point: "warehouses.name",
        item_code: "stock_card.item_code",
        stock_name: "stock_card.item_name",
        lot: "lot",
        expire_date: "expire_date",
        total_quantity: "warehouse_stocks.quantity",
        unit: "string_translations.value"
      };

      const sorter = params.sort[0];
      if (fieldMapping[sorter.field]) {
        sorter.field = fieldMapping[sorter.field];
      }
    }
  }

  disconnect() {
    if (this.tabulator) {
      this.tabulator.destroy();
      this.tabulator = null;
    }
  }
}
