import { TabulatorFull as Tabulator } from 'tabulator-tables';
import { nextPageSvg, prevPageSvg } from "../../helpers/constants";

export function extendLocalizations() {
  Tabulator.extendModule("localize", "langs", {
    "tr": {
      "pagination": {
        "prev": prevPageSvg + " Önceki",
        "prev_title": "Önceki Sayfa",
        "next": "Sonraki " + nextPageSvg,
        "next_title": "Sonraki Sayfa",
        "page_size": "kayıt göster"
      }
    },  
    "en": {
      "pagination": {
        "prev": prevPageSvg + " Previous",
        "prev_title": "Previous Page",
        "next": "Next " + nextPageSvg,
        "next_title": "Next Page",
        "page_size": "show records"
      }
    }
  });
}
