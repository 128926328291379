import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="total-table-information"
export default class extends Controller {
  static targets = ["row", "quantity", "totalRow", "totalQuantity"]

  connect() {
    this.element.addEventListener("nested-form:row-removed", this.updateTotals.bind(this));
    this.element.addEventListener("nested-form:row-added", this.updateTotals.bind(this));
  }

  quantityTargetConnected() {
    this.updateTotals();
  }

  rowTargetConnected() {
    this.updateTotals();
  }

  updateTotals() {
    const rowCount = this.rowTargets.length;
    
    let totalQuantity = 0;
    this.quantityTargets.forEach(input => {
      totalQuantity += parseFloat(input.value) || 0;
    });

    this.totalRowTarget.textContent = rowCount;
    this.totalQuantityTarget.textContent = totalQuantity;
  }

  disconnect() {
    super.disconnect();
  }
}
