import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["row", "count"];

  static values = {
    searchWithAllRow: { type: Boolean, default: true }
  };

  connect() {
    if (this.hasCountTarget) {
      this.updateVisibleRowCount();
    }
  }

  filter(event) {
    const query = event.target.value.toLowerCase();

    this.rowTargets.forEach((row) => {
      if (this.searchWithAllRowValue) {
        row.style.display = row.textContent.toLowerCase().includes(query) ? "" : "none";
      }
      else {
        const columns = row.querySelectorAll('[data-table-search="column"]');

        const columnsMatched = Array.from(columns).some((col) =>
          col.textContent.toLowerCase().includes(query)
        );
  
        row.style.display = columnsMatched ? "" : "none";
      }
    });

    if (this.hasCountTarget) {
      this.updateVisibleRowCount();
    }
  }

  updateVisibleRowCount() {
    const groupCountMap = {};

    this.rowTargets.forEach((row) => {
      const groupId = row.dataset.groupId;

      if (!groupCountMap[groupId]) {
        groupCountMap[groupId] = 0;
      }

      if (row.style.display !== "none") {
        groupCountMap[groupId]++;
      }
    });

    this.countTargets.forEach((countElement) => {
      const groupId = countElement.dataset.groupId;
      countElement.textContent = `(${groupCountMap[groupId]})` || 0;
    });
  }
}
