import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="turbo-frame-trigger"
export default class extends Controller {

  connect(){
    let responseUrl = new URL(window.location.href);
    let turboFrameParam = responseUrl.searchParams.get("turbo_frame");
    let url = responseUrl.searchParams.get("turbo_frame_url");

    let turboFrameElement = document.getElementById(turboFrameParam);

    if (turboFrameElement) {
      turboFrameElement.src = url;
    } 
  }
}
