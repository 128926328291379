import { Controller } from "@hotwired/stimulus"
import Tabulator from "../../extensions/TabulatorExtended";

// Connects to data-controller="tabulator--device-cards"
export default class extends Controller {
  static values = {
    url: String,
    columns: Array,
    locale: String
  }

  connect() {
    this.tabulator = new Tabulator(this.element, {
      ajaxURL: this.urlValue,
      ajaxConfig: "GET",
      layout: "fitColumns",
      pagination: true,
      paginationMode: 'remote',
      paginationSize: 15,
      paginationSizeSelector: [15, 25, 50, 100],
      sortMode: 'remote',
      columns: this.columnsValue,
      locale: this.localeValue,
      dataLoaderLoading: "<div class='tabulator-spinner'></div>",
      paginationCounter: "pageCounter",
      ajaxRequesting: (url, params) => {
        this.processedSorters(params);
        return true;
      },
      columnDefaults: {
        width: 150
      },
    });
  }

  processedSorters(params) {
    if (params.sort.length > 0) {
      const fieldMapping = {
        formatted_device_code: "device_cards.device_code",
        name: "device_cards.name",
        brand: "brands.name",
        device_model: "device_models.model_type",
        serial_number: "serial_number",
        inventory_code: "inventory_code",
        lab: "labs.name",
        consumption_point: "consumption_point.name",
        test_group: "test_groups.name",
        lab_group: "lab_groups.name",
        device_groups: "device_groups.name",
        supplier: "suppliers.name",
        technical_service_manager: "technical_service_managers.name",
        application_supervisor: "application_supervisors.name",
        commissioning_date: "commissioning_date",
        registration_code: "registration_code",
      };

      const sorter = params.sort[0];
      if (fieldMapping[sorter.field]) {
        sorter.field = fieldMapping[sorter.field];
      }
    }
  }

  disconnect() {
    if (this.tabulator) {
      this.tabulator.destroy();
      this.tabulator = null;
    }
  }
}
