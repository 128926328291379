import { Controller } from "@hotwired/stimulus"
import Tabulator from "../../extensions/TabulatorExtended";

// Connects to data-controller="tabulator--stock-cards"
export default class extends Controller {
  static values = {
    url: String,
    columns: Array,
    locale: String
  }

  connect() {
    this.tabulator = new Tabulator(this.element, {
      ajaxURL: this.urlValue,
      ajaxConfig: "GET",
      layout: "fitColumns",
      pagination: true,
      paginationMode: 'remote',
      paginationSize: 15,
      paginationSizeSelector: [15, 25, 50, 100],
      sortMode: 'remote',
      columns: this.columnsValue,
      locale: this.localeValue,
      dataLoaderLoading: "<div class='tabulator-spinner'></div>",
      paginationCounter: "pageCounter",
      ajaxRequesting: (url, params) => {
        this.processedSorters(params);
        return true;
      },
    });
  }

  processedSorters(params) {
    if (params.sort.length > 0) {
      const fieldMapping = {
        item_code: "item_code",
        item_name: "item_name",
        supplier: "suppliers.name",
        parent_item: "parent_item_id",
        brand_company: "brand_id",
        stock_main_group: "stock_main_group_id",
        stock_sub_group: "stock_sub_group_id",
        test_master: "test_master_id",
        test_group: "test_group_id",
        lab_group: "lab_group_id",
        storage_condition: "storage_condition_id",
      };

      const sorter = params.sort[0];
      if (fieldMapping[sorter.field]) {
        sorter.field = fieldMapping[sorter.field];
      }
    }
  }

  disconnect() {
    if (this.tabulator) {
      this.tabulator.destroy();
      this.tabulator = null;
    }
  }
}
