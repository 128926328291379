import { TabulatorFull as Tabulator } from 'tabulator-tables';
import { buildUrl } from "../../helpers/build_request";
import { ascSortSvg, descSortSvg, defaultsortSvg } from "../../helpers/constants";

export function extendFormatters() {
  Tabulator.extendModule("format", "formatters", {
    numberWithHtmlFormatter: (cell, formatterParams, onRendered) => {
      const value = cell.getValue();
      return value && typeof value === 'object' && value.display ? value.display : '-';
    },
    rowNumTotal: (cell) => {
      const table = cell.getTable();
      const pageSize = table.getPageSize();
      const page = table.getPage();
      const indexOnPage = cell.getRow().getPosition(true);
      return (page - 1) * pageSize + indexOnPage;
    },
    tableLinkFormatter: (cell, formatterParams, onRendered) => {
      let url = formatterParams.url;
      const { memberUrl, params, turboFrame, targetBlank } = formatterParams;
      const rowData = cell.getRow().getData();
      const cellData = cell.getValue();
    
      if (cellData.url !== undefined) {
        url = cellData.url;
      }
    
      // If url is a member URL e.g. => /stock_movements/1
      if (memberUrl) {
        url = url.replace(":id", rowData.id);
        url = url.replace(":company_id", rowData.company_id);
      }
    
      // If 'params' are provided, append them to the URL as query parameters 
      // e.g. => /stock_movements/1?stock_card_id=1&warehouse_id=1
      if (params) {
        const queryParameters = params.reduce((acc, paramKey) => {
          acc[paramKey] = rowData[paramKey];
          return acc;
        }, {});
        url = buildUrl(url, queryParameters);
      }
    
      const { value, authorized } = cellData;

      const targetAttribute = targetBlank ? 'target="_blank"' : '';
      // Generate link HTML with Turbo frame support if applicable
      const turboAttributes = turboFrame ? `data-turbo-frame="${turboFrame}"` : `data-turbo="false"`;
      const linkHtml = `<a href="${url}" class="table-link" ${turboAttributes} ${targetAttribute}>${value}</a>`;
    
      // If the user is authorized, display a clickable link; otherwise, just display the value
      return (authorized === false) ? value : linkHtml;
    },
  });
  
  Tabulator.defaultOptions.headerSortElement = function(cell, dir) {
    return dir === "asc" ? ascSortSvg : dir === "desc" ? descSortSvg : defaultsortSvg;
  };  
}