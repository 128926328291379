import NestedForm from 'stimulus-rails-nested-form'
export default class extends NestedForm {
    
  connect() {
    super.connect();
  }

  add(t) {
    t.preventDefault();
    const targetIdentifier = new Date().getTime().toString()
    const e = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, targetIdentifier);
    this.targetTarget.insertAdjacentHTML("afterend", e);
    this.dispatch("row-added");
  }

  remove(t) {
    t.preventDefault();
    const e = t.target.closest(this.wrapperSelectorValue);
    if (e.dataset.newRecord === "true")
      e.remove();
    else {
      e.style.display = "none";
      const a = e.querySelector("input[name*='_destroy']");
      a.value = "1";
    }
    this.dispatch("row-removed");
  }
}